import React, { useEffect, useState } from 'react'
import Page from '../Page'
import api from '../../api/api';
import MaterialTable from 'material-table';
import { localization } from '../../Theme';
import { useHistory } from 'react-router-dom';


export default function Groups() {
    const history = useHistory();
    const [data, setData] = useState([])
    const [groups, setGroups] = useState([])

    async function load() {
        let response = await api.admin.listGroups()
        console.log(`response = ${JSON.stringify(response)}`)
        if (!response)
            return
        console.log(`response = ${response}`)
        setData(response)
    }

    useEffect(() => {
        load();
    }, []);

    const columns = [
        { title: 'Groupe', field: 'name' },
    ];


    return (
        <Page title="Groupes De Notification" >
            <MaterialTable
                title="Groupes"
                columns={columns}
                data={data}
                localization={localization}
                onRowClick={(event, rowData) => {
                    console.log(`rowData = ${JSON.stringify(rowData)}`)
                    history.push("/groupadd", {data: rowData})
                }}
                editable={{
                    onRowAdd: async (newData) => {
                        console.log(JSON.stringify(`newData = ${JSON.stringify(newData)}`))
                        await api.admin.createGroup(newData)
                        load()
                    },
                    onRowDelete: async (oldData) => {
                        console.log(oldData.uuid)
                        await api.admin.deleteGroup(oldData.uuid)
                        load()
                    },
                }}
                detailPanel={[
                    {
                        tooltip: 'Membres',
                        render: rowData => <GroupsDetails rowData={rowData} />,
                    },
                ]}
            />
        </Page>
    );
}

function GroupsDetails({ rowData }) {
    const [data, setData] = useState([])

    const columns = [
        { title: 'Prénom', field: 'firstname' },
        { title: 'Nom', field: 'lastname' },
        { title: 'Email', field: 'email' },
    ];

    async function load() {
        let response = await api.admin.listGroupUsers(rowData.uuid)
        console.log(`response = ${JSON.stringify(response)}`)
        if (!response)
            return
        console.log(`response = ${response}`)
        setData(response)
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <MaterialTable
                title="Comptes"
                columns={columns}
                data={data}
                localization={localization}
                editable={{
                    onRowDelete: async (oldData) => {
                        api.admin.deleteUserFromGroup(rowData.uuid, {profile: oldData.uuid})
                        load()
                    },
                }}
            />
    )
}
