import React, { useState, useEffect } from 'react';
import { IconButton, makeStyles, ListItemText, ListItem, ClickAwayListener, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { FixedSizeList } from 'react-window';
import api from '../api/api';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    right: 30
  },
  iconButton: {
    position: 'relative',
  },
  listText: {
    primary: theme.palette.primary.main
  }
}));

function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem button style={style} key={index} divider>
      <ListItemText
        primary={<Typography variant="body1" style={{ color: '#333' }}>Lab</Typography>}
        secondary={`Item ${index + 1}`}
      />
    </ListItem>
  );
}

export default function NotificationButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [notifications, setNotifications] = useState([])

  const load = async () => {
  }

  useEffect(() => {
    load()
  })

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <IconButton aria-label="show 17 new notifications" color="inherit" onClick={() => setOpen(true)} className={classes.iconButton}>
          <Badge badgeContent={2} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {open && <div className={classes.root}>
          <FixedSizeList height={400} width={300} itemSize={70} itemCount={46}>
            {renderRow}
          </FixedSizeList>
        </div>}
      </div>
    </ClickAwayListener>
  );
}