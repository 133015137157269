import axios from 'axios';

const profile = {
  register: (params) => request('POST', '/registration/register', params),
  login: (params) => request('POST', '/login', params),
  logout: (params) => request('POST', '/logout', params),
  session: (params) => request('GET', '/session', params),
};

const admin = {
  listUsers: (params) => request('GET', `/profile/list`, params),
  updateUser: (profile, params) =>
    request('POST', `/profile/${profile}`, params),
  deleteUser: (profile, params) =>
    request('DELETE', `/profile/${profile}`, params),
  listCommercialInfos: (params) =>
    request('GET', `/commercialInfos/list`, params),
  updateCommercialInfos: (uuid, params) =>
    request('POST', `/commercialInfos/${uuid}`, params),
  getCommissions: (uuid, params) =>
    request('GET', `/commissions/${uuid}`, params),
  createCommissions: (uuid, params) =>
    request('POST', `/commissions/${uuid}`, params),
  sendNotification: (uuid, params) =>
    request('POST', `/notifications/${uuid}`, params),
  readProfile: (uuid, params) => request('GET', `/profile/${uuid}`, params),
  listGroups: (params) => request('GET', `/groups`, params),
  deleteGroup: (group, params) => request('DELETE', `/groups/${group}`, params),
  createGroup: (params) => request('POST', `/groups`, params),
  addUserToGroup: (group, params) =>
    request('POST', `/groupprofiles/${group}`, params),
  deleteUserFromGroup: (group, params) =>
    request('DELETE', `/groupprofiles/${group}`, params),
  listGroupUsers: (group, params) =>
    request('GET', `/groupprofiles/${group}`, params),
  invite: (uuid, params) => request('POST', `/profile/${uuid}/invite`, params),
  setconfig: (params) => request('POST', `/config`, params),
  getconfig: (params) => request('GET', `/config`, params),
  getMood: (profile, params) => request('GET', `/moods/${profile}`, params),
  getActions: (params) => request('GET', `/actions`, params),
  updateAction: (action, params) =>
    request('POST', `/actions/${action}`, params),
  createMission: (params) => {
    request('POST', `/missions`, params);
    console.log('params', params);
  },
  listMissions: (params) => request('GET', `/missions`, params),
  updateMission: (uuid, params) => request('POST', `/missions/${uuid}`, params),
  createProfileMission: (params) => {
    request('POST', `/profilemissions`, params);
    console.log('params', params);
  },
  listProfileMissions: (uuid, params) =>
    request('GET', `/profilemissions/${uuid}`, params),
  updateProfileMission: (uuid, params) =>
    request('POST', `/profilemissions/${uuid}`, params),
};

const manager = {
  getActions: (profile, params) =>
    request('GET', `/manager/actions/${profile}`, params),
  readMood: (uuid, params) => request('GET', `/manager/moods/${uuid}`, params),
  readProfile: (uuid, params) =>
    request('GET', `/manager/profiles/${uuid}`, params),
  readCommercialInfo: (uuid, params) =>
    request('GET', `/manager/commercialInfos/${uuid}`, params),
  setStatus: (uuid, params) =>
    request('POST', `/manager/actions/${uuid}`, params),
  updateAction: (action, params) =>
    request('POST', `/manager/actions/${action}`, params),
  readProfileMission: (mission, params) =>
    request('GET', `/manager/profilemissions/${mission}`, params),
  readMission: (mission, params) =>
    request('GET', `/manager/missions/${mission}`, params),
};

const request = (method, url, params) => {
  axios.defaults.withCredentials = true;
  return axios({
    method: method,
    url: `api${url}`,
    data: params,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response);
    });
};

export default {
  profile,
  admin,
  manager,
};
