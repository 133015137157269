import Notifications from '../components/Pages/Notifications';
import Accounts from '../components/Pages/Accounts';
import CommercialInfos from '../components/Pages/CommercialInfos';
import Commissions from '../components/Pages/Commissions';
import Groups from '../components/Pages/Groups';
import Weather from '../components/Pages/Weather';
import Actions from '../components/Pages/Actions';
import Settings from '../components/Pages/Settings';
import Missions from '../components/Pages/Missions';

const menu = [
  {
    title: 'Actions',
    route: '/actions',
    component: Actions,
    public: false,
    role: ['admin', 'manager'],
  },
  {
    title: 'Comptes',
    route: '/accounts',
    component: Accounts,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Notifications',
    route: '/notifications',
    component: Notifications,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Missions',
    route: '/missions',
    component: Missions,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Commissions',
    route: '/commissions',
    component: Commissions,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Infos Commerciales',
    route: '/commercialinfos',
    component: CommercialInfos,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Groupes',
    route: '/groups',
    component: Groups,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Météo',
    route: '/weather',
    component: Weather,
    public: false,
    role: ['admin'],
  },
  {
    title: 'Configurations',
    route: '/settings',
    component: Settings,
    public: false,
    role: ['admin'],
  },
];
export default menu;
