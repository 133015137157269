import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import {
  Box,
  makeStyles,
  Button,
  AppBar,
  Tabs,
  Tab,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, tableOptions } from '../../Theme';
import { useStore } from 'react-hookstore';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CloudIcon from '@material-ui/icons/Cloud';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(3),
  },
  descMargin: {
    margin: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(3),
  },
  statusForm: {
    margin: theme.spacing(3),
    marginLeft: 'auto',
  },
  descContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(3),
    justifyContent: 'space-between',
  },
  tableTitle: {},
  tableSubtitle: {},
  tableContent: {},
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const getTypes = (n) => {
  switch (n) {
    case 0:
      return '';
    case 1:
      return 'Filleul';
    case 2:
      return 'Info Commerciale';
    case 3:
      return 'Météo';
    case 4:
      return 'Mission';
    default:
      return '';
  }
};

const getDescription = async (action, modification = false) => {
  console.log(`action = ${JSON.stringify(action)}`);
  let description = {};
  let profile = {};

  switch (action.type) {
    case 'Filleul':
      description = await api.manager.readProfile(action.reference);
      profile = await api.manager.readProfile(description.sponsor);
      console.log(`voila le resultat = ${JSON.stringify(description)}`);
      return (
        <DisplaySponsored
          rowData={description}
          modification={modification}
          action={action}
          profile={profile}
        />
      );
    case 'Info Commerciale':
      description = await api.manager.readCommercialInfo(action.reference);
      console.log('commercial info:', description);
      profile = await api.manager.readProfile(description.profile);
      console.log('profile:', profile);
      return (
        <DisplayCommercialInfo
          rowData={description}
          modification={modification}
          action={action}
          profile={profile}
        />
      );
    case 'Météo':
      description = await api.manager.readMood(action.reference);
      profile = await api.manager.readProfile(description.profile);
      return (
        <DisplayMood
          rowData={description}
          modification={modification}
          action={action}
          profile={profile}
        />
      );
    case 'Mission':
      description = await api.manager.readProfileMission(action.reference);
      profile = await api.manager.readProfile(description.profile);
      description = await api.manager.readMission(description.mission);
      return (
        <DisplayMission
          rowData={description}
          modification={modification}
          action={action}
          profile={profile}
        />
      );
    default:
      return null;
  }
};

const UpdateStatusForm = ({ action }) => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(action.status.toString());

  const handleSubmit = async (e) => {
    e.preventDefault();
    await api.manager.setStatus(action.uuid, { status: parseInt(value) });
    history.go();
  };

  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };

  return (
    <div className={classes.statusForm}>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="status"
            name="status"
            value={value}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="0" control={<Radio />} label="A faire" />
            <FormControlLabel value="1" control={<Radio />} label="En Cours" />
            <FormControlLabel value="2" control={<Radio />} label="Fait" />
          </RadioGroup>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Mettre à Jour
          </Button>
        </FormControl>
      </form>
    </div>
  );
};

const DisplayProfile = ({ profile, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <h3>{title || 'Émetteur'}</h3>
      {profile ? (
        <div>
          <Typography variant="body1">
            <strong>Nom: </strong>
            {profile.firstname} {profile.lastname}
          </Typography>
          <Typography variant="body1">
            <strong>Tel: </strong>
            {profile.phone}
          </Typography>
          <Typography variant="body1">
            <strong>E-Mail: </strong>
            {profile.email}
          </Typography>
        </div>
      ) : (
        <span>Aucun</span>
      )}
    </div>
  );
};

const DisplayMood = ({ rowData, modification, action, profile }) => {
  const classes = useStyles();

  const getIcon = (n) => {
    switch (n) {
      case 0:
        return <WbSunnyIcon />;
      case 1:
        return <CloudIcon />;
      case 2:
        return <FlashOnIcon />;
      default:
        return '-';
    }
  };

  return (
    <div className={classes.descContainer}>
      <div className={classes.margin}>
        <Typography variant="body1">
          <strong>Statut: </strong>
          {getIcon(rowData.value)}
        </Typography>
        <Typography variant="body1">
          <strong>Commentaire: </strong>
          {rowData.comment}
        </Typography>
      </div>
      <DisplayProfile profile={profile} />
      {modification && <UpdateStatusForm action={action} />}
    </div>
  );
};

const DisplayCommercialInfo = ({ rowData, modification, action, profile }) => {
  const classes = useStyles();

  return (
    <div className={classes.descContainer}>
      <div className={classes.margin}>
        <Typography variant="body1">
          <strong>Manager: </strong>
          {rowData.manager}
        </Typography>
        <Typography variant="body1">
          <strong>Client: </strong>
          {rowData.client}
        </Typography>
        <Typography variant="body1">
          <strong>Description: </strong>
          {rowData.description}
        </Typography>
        <Typography variant="body1">
          <strong>Commentaire: </strong>
          {rowData.comment}
        </Typography>
      </div>
      <DisplayProfile profile={profile} />
    </div>
  );
};

const DisplaySponsored = ({ rowData, profile }) => {
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <div className={classes.descContainer}>
        <DisplayProfile profile={rowData} title="Filleul" />
        <DisplayProfile profile={profile} />
      </div>
    </div>
  );
};

const DisplayMission = ({ rowData, profile, ...rest }) => {
  const classes = useStyles();
  console.log({ rowData });

  return (
    <div className={classes.margin}>
      <div className={classes.descContainer}>
        <div className={classes.margin}>
          <Typography variant="body1">
            <strong>Mission: </strong>
            {rowData.name}
          </Typography>
          <Typography variant="body1">
            <strong>Client: </strong>
            {rowData.client}
          </Typography>
        </div>
        <DisplayProfile profile={profile} />
      </div>
    </div>
  );
};

const ActionDetails = ({ rowData, modification }) => {
  const [description, setDescription] = useState(<div />);

  const load = async () => {
    const response = await getDescription(rowData, modification);
    setDescription(response);
  };

  useEffect(() => {
    load();
  }, []);
  return description;
};

const getManagers = async () => {
  let result = {};
  const users = await api.admin.listUsers();
  const managers = users.filter((current) => current.role !== 'member');

  for (let i = 0; i < managers.length; i++) {
    result[managers[i].uuid] = managers[i].firstname;
  }

  return result;
};

export default function Actions() {
  const [actionData, setActionData] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [user] = useStore('authStore');
  const [columns, setColumns] = useState([]);

  const load = async () => {
    const temp_actions =
      user.role === 'admin' ? await api.admin.getActions() : [];
    const managers = user.role === 'admin' ? await getManagers() : [];
    let temp_todo = await api.manager.getActions(user.uuid);

    temp_todo = temp_todo.filter((x) => x.status !== 2);
    const admin_columns = [
      { title: 'Manager', field: 'manager', lookup: managers },
      { title: 'Type', field: 'type', editable: 'never' },
      {
        title: 'Statut',
        field: 'status',
        lookup: { 0: 'A faire', 1: 'En Cours', 2: 'Fait' },
      },
      { title: 'Commentaire', field: 'comment' },
      {
        title: 'Créé le',
        field: 'created',
        editable: 'never',
        defaultSort: 'desc',
      },
    ];

    setColumns(admin_columns.reverse());
    temp_actions.map((current) => {
      current.created = new Date(current.created).toLocaleString();
      current.type = getTypes(current.type);
    });
    temp_todo.map((current) => {
      current.created = new Date(current.created).toLocaleString();
      current.type = getTypes(current.type);
    });
    setActionData(temp_actions);
    setTodoData(temp_todo);
  };

  useEffect(() => {
    load();
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Page title="Actions">
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="A faire" {...a11yProps(0)} />
          {user.role === 'admin' && <Tab label="Actions" {...a11yProps(1)} />}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <MaterialTable
          title="A faire"
          columns={columns}
          data={todoData}
          localization={localization}
          options={tableOptions}
          detailPanel={(rowData) => <ActionDetails rowData={rowData} />}
          editable={{
            onRowUpdate: async (
              { created, updated, uuid, ...newData },
              oldData
            ) => {
              newData.status = parseInt(newData.status);
              newData.type = parseInt(newData.type);
              await api.manager.updateAction(oldData.uuid, newData);
              load();
            },
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <MaterialTable
          title="Actions"
          columns={columns}
          data={actionData}
          localization={localization}
          options={tableOptions}
          detailPanel={(rowData) => <ActionDetails rowData={rowData} />}
          editable={{
            onRowUpdate: async (
              { created, updated, uuid, ...newData },
              oldData
            ) => {
              newData.status = parseInt(newData.status);
              newData.type = parseInt(newData.type);
              await api.admin.updateAction(oldData.uuid, newData);
              load();
            },
          }}
        />
      </TabPanel>
    </Page>
  );
}
