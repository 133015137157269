import React, { useEffect, useState } from 'react'
import Page from '../Page'
import api from '../../api/api';
import MaterialTable from 'material-table';
import { localization } from '../../Theme';
import differenceBy from 'lodash/differenceBy'
import _ from 'lodash'

export default function GroupAdd(props) {
    const [ notSubscribed, setNotSubscribed ] = useState([])
    const group = props.location.state.data

    async function load() {
        const users = await api.admin.listUsers()
        const subscribed = await api.admin.listGroupUsers(group.uuid)
        const difference = _(users).differenceBy(subscribed, 'uuid', 'email').value()
        setNotSubscribed(difference)
    }

    useEffect(() => {
        load();
    }, []);

    const columns = [
        { title: 'Prénom', field: 'firstname' },
        { title: 'Nom', field: 'lastname' },
        { title: 'Email', field: 'email' },
    ];

    const addToGroup = (e, data) => {
        data.map(current => {
            api.admin.addUserToGroup(group.uuid, {profile: current.uuid})
        })
        load()    
    }


    return (
        <Page title={`Groupe ${group.name}`} back="/groups" >
            <MaterialTable
                title="Ajouter"
                columns={columns}
                data={notSubscribed}
                localization={localization}
                options={{
                    selection: true
                }}
                actions={[
                    {
                        tooltip: 'Ajouter tous ces utilisateurs à ce groupe',
                        icon: 'add',
                        onClick: addToGroup
                    }
                ]}
            />
        </Page>
    );
}