import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#37474f',
      contrastText: '#FDD966',
    },
    secondary: {
      main: '#FDD966',
      contrastText: '#37474f',
    },
    background: {
      default: '#37474f',
    },
  },
});

export const localization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'lignes',
    labelRowsPerPage: 'lignes par page',
  },
  toolbar: {
    nRowsSelected: '{0} ligne(s) selectionnés',
    searchTooltip: 'Rechercher',
    searchPlaceholder: 'Rechercher',
  },
  header: {
    actions: 'Actions',
  },
  body: {
    emptyDataSourceMessage: 'Rien à afficher',
    editTooltip: 'modifier',
    addTooltip: 'ajouter',
    deleteTooltip: 'supprimer',
    filterRow: {
      filterTooltip: 'Filtre',
    },
    editRow: {
      deleteText: 'Êtes-vous sûr de vouloir effectuer cette suppression?',
    },
  },
};

export const tableOptions = {
  pageSize: 20,
  emptyRowsWhenPaging: false,
};
