import React, { useEffect, useState } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { theme } from './Theme'
import { createStore, useStore } from 'react-hookstore';
import Routes from './Routes/Routes'
import api from './api/api';

createStore('authStore', false);

function App() {
  const [ user, setUser ] = useStore('authStore')
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    (async function anyNameFunction() {
      const response = await api.profile.session()
      response ? localStorage.setItem('isLoggedIn', true) : localStorage.clear()
      setUser(response)
      setIsReady(true)
    })();
}, []);

  if (!isReady)
    return <div />
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <NavBar sticky />
        <Routes />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
