import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import MaterialTable from 'material-table';
import { localization, tableOptions } from '../../Theme';
import { Button, Box } from '@material-ui/core';

function Invite({ rowData }) {
  return (
    <Box m={2}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => api.admin.invite(rowData.uuid)}
      >
        Envoyer Un Nouveau Mot De Passe
      </Button>
    </Box>
  );
}

const getManagers = (users) => {
  let result = {};
  const managers = users.filter((current) => current.role !== 'member');

  managers.forEach((manager) => {
    result[manager.uuid] = `${manager.firstname} ${manager.lastname}`;
  });

  return result;
};

const getMembers = (users) => {
  let result = {};

  users.forEach((member) => {
    result[member.uuid] = `${member.firstname} ${member.lastname}`;
  });

  return result;
};

export default function Accounts() {
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [columns, setColumns] = useState([]);

  async function load() {
    const users = await api.admin.listUsers();
    const managers = getManagers(users);
    const members = getMembers(users);

    const admin_columns = [
      { title: 'Prénom', field: 'firstname' },
      { title: 'Nom', field: 'lastname' },
      { title: 'Email', field: 'email' },
      { title: 'Téléphone', field: 'phone' },
      { title: 'En Mission', field: 'mission', type: 'boolean' },
      { title: 'Parrain', field: 'sponsor', lookup: members },
      { title: 'Win', field: 'win', type: 'boolean' },
      { title: 'Agent De Talent', field: 'talent', lookup: managers },
      {
        title: 'Resp. Administratif',
        field: 'administrative',
        lookup: managers,
      },
      { title: 'Coach', field: 'coach', lookup: managers },
      {
        title: 'Role',
        field: 'role',
        lookup: { admin: 'admin', member: 'membre', manager: 'manager' },
      },
      {
        title: 'Statut',
        field: 'status',
        type: 'numeric',
        lookup: {
          0: 'Non Qualifié',
          1: 'En Cours',
          2: 'Validé',
          3: 'KO',
        },
      },
      { title: 'Créé le', field: 'created', editable: 'never' },
    ];

    setColumns(admin_columns);

    setGroups(groups);
    users.map((current) => {
      current.created = new Date(current.created).toLocaleString();
    });
    setData(users);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Page title="Comptes">
      <MaterialTable
        title="Comptes"
        columns={columns}
        data={data}
        localization={localization}
        options={tableOptions}
        detailPanel={(rowData) => <Invite rowData={rowData} />}
        emptyRowsWhenPaging={false}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 600);
            }),
          onRowUpdate: async (
            { created, updated, uuid, ...newData },
            oldData
          ) => {
            newData.status = parseInt(newData.status);
            await api.admin.updateUser(oldData.uuid, newData);
            load();
          },
          onRowDelete: async (oldData) => {
            await api.admin.deleteUser(oldData.uuid);
            load();
          },
        }}
      />
    </Page>
  );
}
