import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import {
  TextField,
  Paper,
  Box,
  makeStyles,
  Button,
  AppBar,
  Tabs,
  Tab,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Snackbar,
  InputAdornment,
  Grid,
  IconButton,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization } from '../../Theme';
import MuiAlert from '@material-ui/lab/Alert';
import TextFieldIcon from '../TextFieldIcon';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CloudIcon from '@material-ui/icons/Cloud';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MoodForm = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const handleClick = async () => {
    const { uuid, created, updated, ...newData } = data;
    console.log('new', newData);
    if (newData.messagelink === '') newData.messagelink = null;
    console.log('new', newData);
    await api.admin.setconfig(newData);
    setOpen(true);
  };

  const load = async () => {
    const response = await api.admin.getconfig();
    console.log(`config = ${JSON.stringify(response)}`);
    setData(response);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Paper className={classes.paper}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          La météo a bien été mise à jour
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Message"
            variant="outlined"
            className={classes.input}
            value={data.message}
            defaultValue=" "
            color="primary"
            fullWidth
            onChange={(e) => setData({ ...data, message: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="URL message"
            variant="outlined"
            className={classes.input}
            value={data.messagelink}
            defaultValue=" "
            color="primary"
            type="url"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setData({ ...data, messagelink: '' })}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setData({ ...data, messagelink: e.target.value })}
          />
        </Grid>
      </Grid>
      <Box>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WbSunnyIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          value={data.good}
          className={classes.input}
          defaultValue=" "
          fullWidth
          multiline="true"
          onChange={(e) => setData({ ...data, good: e.target.value })}
        />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CloudIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          className={classes.input}
          value={data.normal}
          defaultValue=" "
          fullWidth
          multiline="true"
          onChange={(e) => setData({ ...data, normal: e.target.value })}
        />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FlashOnIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          className={classes.input}
          value={data.bad}
          defaultValue=" "
          fullWidth
          multiline="true"
          onChange={(e) => setData({ ...data, bad: e.target.value })}
        />
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Mettre à Jour
      </Button>
    </Paper>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getIcon = (n) => {
  switch (n) {
    case 0:
      return <WbSunnyIcon />;
    case 1:
      return <CloudIcon />;
    case 2:
      return <FlashOnIcon />;
    default:
      return '-';
  }
};
function MoodHistory({ rowData }) {
  const [moodHistory, setMoodHistory] = useState([]);

  const load = async () => {
    const response = await api.admin.getMood(rowData.uuid);
    response.map((current) => {
      current.value = getIcon(current.value);
      current.created = new Date(current.created).toLocaleString();
    });
    console.log(`mood = ${JSON.stringify(response)}`);
    setMoodHistory(response.reverse() || []);
  };

  useEffect(() => {
    load();
  }, []);

  const columns = [
    { title: 'Météo', field: 'value' },
    { title: 'Commentaire', field: 'comment' },
    { title: 'Date', field: 'created' },
  ];

  return (
    <MaterialTable
      title={`Météos de ${rowData.firstname} ${rowData.lastname}`}
      columns={columns}
      data={moodHistory}
      localization={localization}
    />
  );
}

export default function Weather() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadApp() {
      let response = await api.admin.listUsers();
      response.map((current) => {
        current.created = new Date(current.created).toLocaleString();
      });
      setData(response);
    }
    loadApp();
  }, []);

  const columns = [
    { title: 'Prénom', field: 'firstname' },
    { title: 'Nom', field: 'lastname' },
    { title: 'Email', field: 'email' },
    { title: 'Téléphone', field: 'phone' },
  ];
  return (
    <Page title="Meteo">
      <MoodForm />
      <MaterialTable
        title="Historique des météos"
        columns={columns}
        data={data}
        localization={localization}
        detailPanel={(rowData) => <MoodHistory rowData={rowData} />}
      />
    </Page>
  );
}
