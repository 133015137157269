import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import {
  TextField,
  Paper,
  Box,
  makeStyles,
  Button,
  AppBar,
  Tabs,
  Tab,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Snackbar,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization } from '../../Theme';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Notifications() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [radioValue, setRadioValue] = useState();
  const [groups, setGroups] = useState([]);
  const [textError, setTextError] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function loadApp() {
      let response = await api.admin.listUsers();
      const temp_groups = await api.admin.listGroups();
      if (!temp_groups) return;
      setGroups(temp_groups);
      console.log(`response = ${JSON.stringify(response)}`);
      if (!response) return;
      response.map((current) => {
        current.created = new Date(current.created).toLocaleString();
      });
      console.log(`response = ${response}`);
      setData(response);
    }
    loadApp();
  }, []);

  const columns = [
    { title: 'Prénom', field: 'firstname' },
    { title: 'Nom', field: 'lastname' },
    { title: 'Email', field: 'email' },
    { title: 'Créé le', field: 'created' },
  ];

  const sendNotificationsFromUsers = (e, data) => {
    data.map((current) => {
      api.admin.sendNotification(current.uuid, { title: title, body: body });
    });
    setOpen(true);
  };

  const sendNotificationsFromGroups = async (e) => {
    e.preventDefault();
    setTextError('');
    if (!radioValue) {
      setTextError('Vous devez choisir un groupe');
      return;
    }
    const users = await api.admin.listGroupUsers(radioValue);
    users.map((current) => {
      api.admin.sendNotification(current.uuid, { title: title, body: body });
      if (current.expotoken)
        api.admin.pushNotification({
          to: current.expotoken,
          title: title,
          body: body,
        });
      console.log(current.email);
    });
    setOpen(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Page title="Notifications">
      <Paper className={classes.paper}>
        <Box>
          <TextField
            label="Titre"
            variant="outlined"
            className={classes.input}
            color="primary"
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            label="Texte"
            variant="outlined"
            className={classes.input}
            fullWidth
            multiline="true"
            onChange={(e) => setBody(e.target.value)}
          />
        </Box>
      </Paper>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Utilisateurs" {...a11yProps(0)} />
          <Tab label="Groupe" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <MaterialTable
          title="Comptes"
          columns={columns}
          data={data}
          localization={localization}
          actions={[
            {
              tooltip: 'Envoyer la notification à tous ces utilisateurs',
              icon: 'send',
              onClick: sendNotificationsFromUsers,
            },
          ]}
          options={{
            selection: true,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Paper>
          <Typography className={classes.padding} variant="h6" component="h2">
            Groupes
          </Typography>
          <form onSubmit={sendNotificationsFromGroups}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="groups"
                name="groups"
                value={radioValue}
                onChange={handleRadioChange}
                className={classes.margin}
              >
                {groups.map((current) => {
                  return (
                    <FormControlLabel
                      value={current.uuid}
                      control={<Radio />}
                      label={current.name}
                    />
                  );
                })}
              </RadioGroup>
              <FormHelperText className={classes.margin} error>
                {textError}
              </FormHelperText>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.margin}
              >
                Envoyer
              </Button>
            </FormControl>
          </form>
        </Paper>
      </TabPanel>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Les notifications ont bien été envoyées
        </Alert>
      </Snackbar>
    </Page>
  );
}
