import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import MaterialTable from 'material-table';
import { localization } from '../../Theme';

export default function Commissions() {
  const [data, setData] = useState([]);

  async function load() {
    let response = await api.admin.listUsers();
    if (!response) return;
    console.log(`response = ${JSON.stringify(response)}`);
    response.map((current) => {
      current.created = new Date(current.created).toLocaleString();
    });
    console.log(`response = ${response}`);
    setData(response);
  }

  useEffect(() => {
    load();
  }, []);

  const columns = [
    { title: 'Prénom', field: 'firstname' },
    { title: 'Nom', field: 'lastname' },
    { title: 'Email', field: 'email' },
    { title: 'Téléphone', field: 'phone' },
  ];

  return (
    <Page title="Commissions">
      <MaterialTable
        title="Comptes"
        columns={columns}
        data={data}
        localization={localization}
        detailPanel={[
          {
            tooltip: 'Commissions du membre',
            render: (rowData) => <CommissionsDetails rowData={rowData} />,
          },
        ]}
      />
    </Page>
  );
}

function CommissionsDetails(props) {
  const [data, setData] = useState([]);

  const columns = [
    { title: 'Créé le', field: 'created', editable: 'never', readonly: true },
    { title: 'Description', field: 'description', type: 'string' },
    { title: 'Date', field: 'date', type: 'date' },
    {
      title: 'Montant (€)',
      field: 'amount',
      type: 'numeric',
      initialEditValue: 0,
    },
  ];

  async function load() {
    let response = await api.admin.getCommissions(props.rowData.uuid);
    console.log(`response = ${JSON.stringify(response)}`);
    if (!response) return;
    response.map((current) => {
      current.created = new Date(current.created).toLocaleString();
      current.date = new Date(current.date).toLocaleString();
    });
    console.log(`response = ${response}`);
    setData(response);
  }

  useEffect(() => {
    load();
  }, []);
  return (
    <MaterialTable
      title="Commissions"
      columns={columns}
      data={data}
      localization={localization}
      editable={{
        isEditHidden: () => true,
        isDeleteHidden: () => true,
        onRowAdd: async (newData) => {
          newData.date = new Date(newData.date).getTime();
          newData.amount = parseFloat(newData.amount);
          await api.admin.createCommissions(props.rowData.uuid, newData);
          load();
        },
        onRowUpdate: async (
          { created, updated, uuid, ...newData },
          oldData
        ) => {
          load();
        },
        onRowDelete: async (oldData) => {
          load();
        },
      }}
    />
  );
}
