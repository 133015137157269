import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import MaterialTable from 'material-table';
import { localization } from '../../Theme';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(3),
  },
  descContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(3),
    justifyContent: 'space-between',
  },
}));

const DisplayProfile = ({ profile, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <h3>{title || 'Émetteur'}</h3>
      {profile ? (
        <div>
          <Typography variant="body1">
            <strong>Nom: </strong>
            {profile.firstname} {profile.lastname}
          </Typography>
          <Typography variant="body1">
            <strong>Tel: </strong>
            {profile.phone}
          </Typography>
          <Typography variant="body1">
            <strong>E-Mail: </strong>
            {profile.email}
          </Typography>
        </div>
      ) : (
        <span>Aucun</span>
      )}
    </div>
  );
};

function CommercialInfosDetails({ rowData }) {
  const [profile, setProfile] = useState([]);
  const classes = useStyles();

  async function load() {
    const response = await api.admin.readProfile(rowData.profile);
    console.log(`profile = ${JSON.stringify(response)}`);
    setProfile(response);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <div className={classes.descContainer}>
      <div className={classes.margin}>
        <Typography variant="body1">
          <strong>Manager: </strong>
          {rowData.manager}
        </Typography>
        <Typography variant="body1">
          <strong>Client: </strong>
          {rowData.client}
        </Typography>
        <Typography variant="body1">
          <strong>Description: </strong>
          {rowData.description}
        </Typography>
        <Typography variant="body1">
          <strong>Commentaire: </strong>
          {rowData.comment}
        </Typography>
      </div>
      <DisplayProfile profile={profile} />
    </div>
  );
}
export default function Commissions() {
  const [data, setData] = useState([]);

  async function load() {
    let response = await api.admin.listCommercialInfos();
    console.log(`response = ${JSON.stringify(response)}`);
    if (!response) return;
    response.map((current) => {
      current.created = new Date(current.created).toLocaleString();
    });
    console.log(`response = ${response}`);
    setData(response);
  }

  useEffect(() => {
    load();
  }, []);

  const columns = [
    { title: 'Manager', field: 'manager' },
    { title: 'Client', field: 'client' },
    {
      title: 'Statut',
      field: 'status',
      lookup: {
        0: 'Soumis',
        1: 'En Cours',
        2: 'Gagné',
        3: 'Perdu',
      },
    },
  ];

  return (
    <Page title="Infos Commerciales">
      <MaterialTable
        title="Informations Commerciales"
        columns={columns}
        data={data}
        localization={localization}
        detailPanel={(rowData) => <CommercialInfosDetails rowData={rowData} />}
        editable={{
          onRowUpdate: async (
            { created, updated, uuid, ...newData },
            oldData
          ) => {
            newData.status = parseInt(newData.status);
            await api.admin.updateCommercialInfos(oldData.uuid, newData);
            load();
          },
        }}
      />
    </Page>
  );
}
