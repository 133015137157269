import React, { useEffect, useState } from 'react';
import Page from '../Page';
import api from '../../api/api';
import MaterialTable from 'material-table';
import { localization, tableOptions } from '../../Theme';
import { TextField } from '@material-ui/core';

const getManagersNames = (users) => {
  let result = {};
  const managers = users.filter((current) => current.role !== 'member');

  managers.forEach((manager) => {
    result[manager.uuid] = `${manager.firstname} ${manager.lastname}`;
  });

  return result;
};

const getMembersNames = (users) => {
  let result = {};
  console.log('users', users);
  users.forEach((member) => {
    result[member.uuid] = `${member.firstname} ${member.lastname}`;
  });

  return result;
};

const MissionProfiles = ({ mission }) => {
  const [profileMissions, setProfileMissions] = useState([]);
  const [columns, setColumns] = useState([]);

  const load = async () => {
    console.log('mission', mission);
    const temp_profileMissions = await api.admin.listProfileMissions(mission);
    if (!temp_profileMissions) return;
    temp_profileMissions.map((current) => {
      current.created = new Date(current.created).toLocaleString();
    });
    setProfileMissions(temp_profileMissions);
    const members = getMembersNames(await api.admin.listUsers());
    setColumns([
      { title: 'Profile', field: 'profile', lookup: members },
      { title: 'Commentaire', field: 'comment' },
      {
        title: 'Statut',
        field: 'status',
        type: 'numeric',
        lookup: {
          0: 'Intéressé',
          1: 'Positionné',
          2: 'Refusé',
          3: 'Gagné',
        },
      },
    ]);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <MaterialTable
        title="Missions"
        columns={columns}
        data={profileMissions}
        localization={localization}
        options={tableOptions}
        emptyRowsWhenPaging={false}
        editable={{
          onRowAdd: async (newData) => {
            newData.status = parseInt(newData.status);
            await api.admin.createProfileMission({ mission, ...newData });
            load();
          },
          onRowUpdate: async (
            { created, updated, uuid, ...newData },
            oldData
          ) => {
            newData.status = parseInt(newData.status);
            await api.admin.updateProfileMission(oldData.uuid, newData);
            load();
          },
        }}
      />
    </div>
  );
};

export default function Missions() {
  const [missions, setMissions] = useState([]);
  const [columns, setColumns] = useState([]);

  const load = async () => {
    const users = await api.admin.listUsers();
    const managers = getManagersNames(users);
    setColumns([
      { title: 'Mission', field: 'name' },
      { title: 'Client', field: 'client' },
      { title: 'Lieu', field: 'location' },
      { title: 'Contact', field: 'contact' },
      {
        title: 'TJM vente (€)',
        field: 'adtsell',
        type: 'numeric',
        initialEditValue: 0,
      },
      {
        title: 'TJM achat (€)',
        field: 'adtbuy',
        type: 'numeric',
        initialEditValue: 0,
      },
      { title: 'Date début', field: 'start', type: 'date' },
      { title: 'Agent De Talent', field: 'talent', lookup: managers },
      { title: 'Durée estimée (jours)', field: 'duration', type: 'numeric' },
      { title: 'Commentaire', field: 'comment' },
      {
        title: 'Statut',
        field: 'status',
        type: 'numeric',
        lookup: {
          0: 'Ouverte',
          1: 'Fermée',
        },
      },
      {
        title: 'Rythme (%)',
        field: 'rythm',
        initialEditValue: 100,
        editComponent: ({ value, onChange }) => {
          return (
            <TextField
              type="number"
              inputProps={{ min: 0, max: 100 }}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          );
        },
      },
      {
        title: 'Créé le',
        field: 'created',
        type: 'date',
        editable: 'never',
        readonly: true,
      },
    ]);

    const temp_missions = await api.admin.listMissions();
    if (!temp_missions) return;
    temp_missions.map((current) => {
      current.created = new Date(current.created).toLocaleString();
      current.start = current.start && new Date(current.start).toLocaleString();
    });
    setMissions(temp_missions);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Page title="Missions">
      <MaterialTable
        title="Missions"
        columns={columns}
        data={missions}
        localization={localization}
        options={tableOptions}
        detailPanel={({ uuid }) => <MissionProfiles mission={uuid} />}
        emptyRowsWhenPaging={false}
        editable={{
          onRowAdd: async (newData) => {
            newData.adtsell = parseFloat(newData.adtsell);
            newData.adtbuy = parseFloat(newData.adtbuy);
            newData.start = new Date(newData.start).getTime();
            newData.duration = parseInt(newData.duration);
            newData.status = parseInt(newData.status);
            newData.rythm = parseInt(newData.rythm);
            await api.admin.createMission(newData);
            load();
          },
          onRowUpdate: async (
            { created, updated, uuid, ...newData },
            oldData
          ) => {
            newData.adtsell = parseFloat(newData.adtsell);
            newData.adtbuy = parseFloat(newData.adtbuy);
            newData.start = new Date(newData.start).getTime();
            newData.duration = parseInt(newData.duration);
            newData.status = parseInt(newData.status);
            newData.rythm = parseInt(newData.rythm);
            await api.admin.updateMission(oldData.uuid, newData);
            load();
          },
        }}
      />
    </Page>
  );
}
