import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../components/Pages/Home";
import Accounts from "../components/Pages/Accounts";
import Actions from "../components/Pages/Actions";
import SignIn from "../components/Pages/SignIn";
import SignUp from "../components/Pages/SignUp";
import menu from "../globals/Menu";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import PageNotFound from "../components/Pages/PageNotFound";
import GroupAdd from "../components/Pages/GroupAdd";
import { useStore } from "react-hookstore";

export default function Routes() {
    const [user, setUser] = useStore('authStore')

    return (
        <Switch>
            <PrivateRoute path="/" exact component={Actions} />
            <PublicRoute path="/signin" exact component={SignIn} />
            <PublicRoute path="/signup" exact component={SignUp} />
            <PrivateRoute path="/groupadd" exact component={GroupAdd} />
            {menu.map((item) => {
                if (item.public) {
                    return <Route path={item.route} exact component={item.component} />
                } else {
                    if (user && item.role.includes(user.role)) {
                        return <PrivateRoute path={item.route} exact component={item.component} />
                    }
                }
            })}
            <Route component={PageNotFound} />
        </Switch>
    );
}
