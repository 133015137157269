import React, { useEffect, useState } from 'react';
import Page from '../Page';
import UserSettings from './UserSettings';
import {
  Paper,
  makeStyles,
  TextField,
  Box,
  Button,
  MenuItem,
  Snackbar,
} from '@material-ui/core';
import { LocalDining } from '@material-ui/icons';
import api from '../../api/api';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    margin: theme.spacing(1),
    width: '40ch',
  },
}));

export default function PrivatePage() {
  const classes = useStyles();
  const [managers, setManagers] = useState([]);
  const [config, setConfig] = useState({
    talent: '',
    administrative: '',
    coach: '',
    actionmanager: '',
    missionexpiration: 0,
  });
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const load = async () => {
    const tmp_config = await api.admin.getconfig();
    const users = await api.admin.listUsers();
    setConfig(tmp_config);
    const tmp_managers = users.filter((current) => current.role !== 'member');
    setManagers(tmp_managers);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect((e) => {
    load();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`data = ${JSON.stringify(data)}`);
    await api.admin.setconfig(data);
    setOpen(true);
  };
  return (
    <Page title="Configurations">
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              className={classes.input}
              select
              variant="outlined"
              color="primary"
              name="talent"
              value={config.talent}
              label="Agent De Talents"
              onChange={(e) => {
                setData({ ...data, talent: e.target.value });
                setConfig({ ...config, talent: e.target.value });
              }}
            >
              {managers.map((manager) => (
                <MenuItem key={manager.uuid} value={manager.uuid}>
                  {manager.firstname} {manager.lastname}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              color="primary"
              select
              className={classes.input}
              value={config.administrative}
              name="administrative"
              onChange={(e) => {
                setData({ ...data, administrative: e.target.value });
                setConfig({ ...config, administrative: e.target.value });
              }}
              label="Responsable Administratif"
            >
              {managers.map((manager) => (
                <MenuItem key={manager.uuid} value={manager.uuid}>
                  {manager.firstname} {manager.lastname}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              color="primary"
              select
              className={classes.input}
              value={config.coach}
              label="Coach"
              name="coach"
              onChange={(e) => {
                setData({ ...data, coach: e.target.value });
                setConfig({ ...config, coach: e.target.value });
              }}
            >
              {managers.map((manager) => (
                <MenuItem key={manager.uuid} value={manager.uuid}>
                  {manager.firstname} {manager.lastname}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              color="primary"
              select
              className={classes.input}
              name="actionmanager"
              value={config.actionmanager}
              onChange={(e) => {
                setData({ ...data, actionmanager: e.target.value });
                setConfig({ ...config, actionmanager: e.target.value });
              }}
              label="Responsable Actions"
            >
              {managers.map((manager) => (
                <MenuItem key={manager.uuid} value={manager.uuid}>
                  {manager.firstname} {manager.lastname}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              color="primary"
              type="number"
              className={classes.input}
              name="missionexpiration"
              InputLabelProps={{
                shrink: true,
              }}
              value={config.missionexpiration}
              onChange={(e) => {
                setData({
                  ...data,
                  missionexpiration: parseInt(e.target.value),
                });
                setConfig({
                  ...config,
                  missionexpiration: parseInt(e.target.value),
                });
              }}
              label="Jours de visibilité d'une mission fermée"
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.input}
          >
            Mettre à Jour
          </Button>
        </form>
      </Paper>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          La configuration a bien été mise à jour
        </Alert>
      </Snackbar>
    </Page>
  );
}
